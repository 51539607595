import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import BnbBusiness from "../../contracts/BnbBusiness.json";
import getWeb3 from "../../getWeb3";
const defaultAmountArr = ['0.1','0.2','0.4','0.6','1','4','10'];

class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading:true,
        web3: null,
        accounts: null,
        contract: null,
        contractAddress: null,
        sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
        userInfo:{},
        TotalBonus:0,
        totalWithdrawn:0,
        totalDeposits:0,
        totalInvested:0,
        totalUsers:0,
        networkMain:false,
        YourAddress: '-',
        YourAddressfull: null,
        amountBNB:null,
        user_referer:'-',
        balance:null,
        adminFee:null,
        adminFee_percentage:5,
        reinvest_percentage:0,
        withdrwal_percentage:0,
        GetDownlineIncomeByUserId:0,
        GetUplineIncomeByUserId:0,
        communityLevels:[],
        sponsorList:[]
      }
    }

    componentDidMount = async () => {
      try {
        // Get network provider and web3 instance.
        const web3 = await getWeb3();
  
        // Use web3 to get the user's accounts.
        setInterval(async()=>{
          const accounts = await web3.eth.getAccounts();
         
          if(this.state.accounts !== accounts[0]){
          // Get the contract instance.
          const networkId = await web3.eth.net.getId();
          //alert(networkId);
          //if(networkId !== 56) throw "Please connect Mainnet"; 
          const deployedNetwork = BnbBusiness.networks[networkId];
          const instance = new web3.eth.Contract(
            BnbBusiness.abi,
            deployedNetwork && deployedNetwork.address,
          );   
          
          this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
          }
       },1000);

      } catch (error) {
        // Catch any errors for any of the above operations.
        // alert(
        //   error
        // );
        this.setState({networkMain:false});
        console.error(error);
      }
    };

    fetchData = async() => {
      const { accounts, contract } = this.state;

      console.log(this.state.contractAddress);

      let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
      let YourAddressfull = accounts;
      let userInfo = await contract.methods.users(accounts).call();
      userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
      userInfo.amount = parseFloat(userInfo.amount).toFixed(5);
      userInfo.referrerBonus = this.state.web3.utils.fromWei(userInfo.referrerBonus, 'ether');
      userInfo.referrerBonus = parseFloat(userInfo.referrerBonus).toFixed(5);
      userInfo.totalWithdrawn = this.state.web3.utils.fromWei(userInfo.totalWithdrawn, 'ether');
      userInfo.totalWithdrawn = parseFloat(userInfo.totalWithdrawn).toFixed(5);
      let user_referer = '-';
      if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
        user_referer = userInfo.referrer;
        user_referer = user_referer.slice(0, 10) + '.....' + user_referer.slice(user_referer.length-5, user_referer.length);
      }
      
      let TotalBonus = await contract.methods.TotalBonus(accounts).call();
      TotalBonus = this.state.web3.utils.fromWei(TotalBonus, 'ether');
      TotalBonus = parseFloat(TotalBonus).toFixed(5);
      let totalWithdrawn = await contract.methods.totalWithdrawn().call();
      totalWithdrawn = this.state.web3.utils.fromWei(totalWithdrawn, 'ether');
      totalWithdrawn = parseFloat(totalWithdrawn).toFixed(5);
      let totalDeposits = await contract.methods.totalDeposits().call();
      totalDeposits = parseFloat(totalDeposits).toFixed(5);
      let totalInvested = await contract.methods.totalInvested().call();
      totalInvested = this.state.web3.utils.fromWei(totalInvested, 'ether');
      totalInvested = parseFloat(totalInvested).toFixed(5);
      let totalUsers = await contract.methods.totalUsers().call();

      let balance = TotalBonus;
      let adminFee = balance*this.state.adminFee_percentage/100;
      balance = balance - adminFee;


      let getEligibleWithdrawal = await contract.methods.getEligibleWithdrawal(accounts).call();
      let reinvest_percentage = getEligibleWithdrawal.reivest;
      let withdrwal_percentage = getEligibleWithdrawal.withdrwal;

      let singleUplineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleUplineBonusTaken, 'ether');
      let singleDownlineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleDownlineBonusTaken, 'ether');

      let GetDownlineIncomeByUserId = await contract.methods.GetDownlineIncomeByUserId(accounts).call();
      GetDownlineIncomeByUserId = this.state.web3.utils.fromWei(GetDownlineIncomeByUserId, 'ether');
      GetDownlineIncomeByUserId = GetDownlineIncomeByUserId - singleDownlineBonusTaken;
      GetDownlineIncomeByUserId = parseFloat(GetDownlineIncomeByUserId).toFixed(5);

      let GetUplineIncomeByUserId = await contract.methods.GetUplineIncomeByUserId(accounts).call();
      GetUplineIncomeByUserId = this.state.web3.utils.fromWei(GetUplineIncomeByUserId, 'ether');
      GetUplineIncomeByUserId = GetUplineIncomeByUserId - singleUplineBonusTaken;
      GetUplineIncomeByUserId = parseFloat(GetUplineIncomeByUserId).toFixed(5);

      this.setState({ 
        userInfo,
        TotalBonus,
        totalWithdrawn,
        totalDeposits,
        totalInvested,
        totalUsers,
        //networkMain:true,
        YourAddress:YourAddress,
        YourAddressfull:YourAddressfull,
        user_referer:user_referer,
        balance,
        adminFee,
        reinvest_percentage,
        withdrwal_percentage,
        GetUplineIncomeByUserId,
        GetDownlineIncomeByUserId,
        loading:false
      },async()=>{
        const { accounts, contract } = this.state;
        let getEligibleLevelCountForUpline = await contract.methods.getEligibleLevelCountForUpline(accounts).call();
        let uplineCount = getEligibleLevelCountForUpline.uplineCount;
        let downlineCount = getEligibleLevelCountForUpline.downlineCount;
        let communityLevels = [];
        let upline_users = [];
        let downline_users = [];
        let current_user = accounts;
        let userInfo = await contract.methods.users(current_user).call();
        for(let i=1;i<=uplineCount;i++){          
          if(current_user == userInfo.singleUpline) continue;
          current_user = userInfo.singleUpline;
          let emptyAddress = /^0x0+$/.test(current_user);
          if(emptyAddress) continue;
          userInfo = await contract.methods.users(current_user).call();
          let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
          let income = (investment/100).toFixed(5);
          investment = parseFloat(investment).toFixed(5);
          upline_users.push({level:i,levelText:'Upline-'+i,type:'upline',username:current_user,investment:investment,income:income});
        }
        upline_users.sort((a, b) => b.level>a.level? 1 : -1);
        upline_users.map(function(val, index){
          communityLevels.push(val);
        });
        let currentUserInfo = await contract.methods.users(accounts).call();
        let investment = this.state.web3.utils.fromWei(currentUserInfo.amount, 'ether');
        let income = (investment/100).toFixed(5);
        income = (0).toFixed(5);
        investment = parseFloat(investment).toFixed(5);
        let main_user = {level:0,levelText:'You',type:'main_user',username:accounts,investment:investment,income:income};
        communityLevels.push(main_user);
        current_user = accounts;
        userInfo = await contract.methods.users(current_user).call();
        for(let i=1;i<=downlineCount;i++){
          if(current_user == userInfo.singleDownline) continue;
          current_user = userInfo.singleDownline;
          let emptyAddress = /^0x0+$/.test(current_user);
          if(emptyAddress) continue;
          userInfo = await contract.methods.users(current_user).call();
          let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
          let income = (investment/100).toFixed(5);
          investment = parseFloat(investment).toFixed(5);
          downline_users.push({level:i,levelText:'Downline-'+i,type:'downline',username:current_user,investment:investment,income:income});
        }
        downline_users.map(function(val, index){
          communityLevels.push(val);
        });

        let sponsorList = [];
        let count = 0;
        for(let i=0;i<6;i++){
          let referral_stage = await contract.methods.referral_stage(accounts,i).call();
          let _investment = this.state.web3.utils.fromWei(referral_stage._investment, 'ether');
          //let ref_bonuses = await contract.methods.ref_bonuses(i).call();
          let percentage_amount = this.state.web3.utils.fromWei(referral_stage._bonus, 'ether'); //(_investment*ref_bonuses/100).toFixed(5);
          _investment = parseFloat(_investment).toFixed(5);
          percentage_amount = parseFloat(percentage_amount).toFixed(5);
          let _noOfUser = referral_stage._noOfUser;
          
          if(i == 0){
            count = _noOfUser;
          }
          let status = '';
          if(i>1 && count<4){
            status = 'Unqualified';
          }
          sponsorList.push({status:status,level:i+1,_investment:_investment,_noOfUser:_noOfUser,percentage_amount:percentage_amount});
        }
        

        this.setState({communityLevels, sponsorList});
        // console.log(communityLevels);
        
        // console.log(sponsorList);
         
      });     
    }

    doJoinNow = async () => {
      //const weiValue = this.state.web3.utils.toWei('1', 'ether');
      //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
      const { accounts, contract } = this.state;
      let sponsor_address = this.state.sponsor_address;
      let userInfo = await contract.methods.users(accounts).call();
      if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
        sponsor_address = userInfo.referrer;
      }
      else if(!sponsor_address){
        this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
        this.setState({sponsor_addressError:true});
        return false;
      }

      if(!this.state.amountBNB){
        this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
        this.setState({amountError:true});
        return false;
      }
      
      let balance = await this.state.web3.eth.getBalance(this.state.accounts);
      let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
      let amountBNB = this.state.amountBNB;
      if(sponsor_address){
        if(balanceEthVal >= amountBNB){
          try {
            this.setState({loading:true});
            this.state.contract.events.NewDeposit((err, eventResult) => {
              console.log('eventResult',eventResult);
              if (!err) {
                let uerAddress = this.state.accounts;
                if(eventResult.returnValues[0] == uerAddress){
                  this.fetchData();
                  this.setState({loading:false});
                  this.props.enqueueSnackbar("Joined Successfully!",{ variant: 'success' });                
                }              
              }else{
                console.log(err);
                this.setState({loading:false});
                this.props.enqueueSnackbar('Some Network Error Occurred!');              
              }
            });

            let weiValue = this.state.web3.utils.toWei(amountBNB, 'ether');
            let invest = await this.state.contract.methods.invest(sponsor_address).send(
              {
                from: this.state.accounts,
                value:weiValue
              }
            );
            console.log(invest);
          }
          catch(err) {
            this.setState({loading:false});
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }          
        }else{
          this.setState({loading:false});
          this.props.enqueueSnackbar("Insufficient BNB Balance!",{ variant: 'error' });          
        }        
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
      }
    }

    doWithdrawal = async () => {
      if(this.state.TotalBonus > 0){
        this.setState({loading:true});
        this.state.contract.events.Withdrawn((err, eventResult) => {
          console.log('eventResult',eventResult);
          console.log('err',err);
          if (!err) {
            let uerAddress = this.state.accounts;
            if(eventResult.returnValues[0] == uerAddress){
              this.fetchData();
              this.setState({loading:false});
              this.props.enqueueSnackbar("Withdrawal Successfully!",{ variant: 'success' });                
            }              
          }else{
            console.log(err);
            this.setState({loading:false});
            this.props.enqueueSnackbar('Some Network Error Occurred!');              
          }        
        });

        let withdrawal = await this.state.contract.methods.withdrawal().send(
          { 
            from: this.state.accounts
          }
        );
        console.log('withdrawal',withdrawal); 
      }else{
        this.props.enqueueSnackbar('Insufficient balance!');
      }
           
    }

    
    render() {
      return (
        <Frame withFooter={true}>
          <div id="particles-js"></div>
          {/* <div class="modal fade" id="modal_show">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Important Notice</h5>
                  <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body">
                  <h6>September 13, 2021</h6>
                  <p>Dear Users,</p>
                  <p>BNB Business was under DDOS Attack. Due to the severity of the attack, BNB Business was not accessible across several locations for the last 36 hours. </p>
                  <p>We have recovered the website and BNB Business is now live.</p>
                  <p>In case, you are unable to operate BNB Business from your location, please connect your wallet with the smart contract and stay connected. </p>
                  <p>Thank you for your patience.</p>
                  <hr className="mb-10" />
                  <p className="text-right mb-0">Team</p>
                  <p className="text-right logo_text"><strong>BNB Business</strong></p>
                </div>      
              </div>
            </div>
          </div> */}

        <div className="modal fade show" id="Modal_popup" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-keyboard="false" data-backdrop="static" aria-modal="true">
          <div className="modal-dialog modal-lg modal_margin_t">
            <div className="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Important Notice</h5>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
              <p><strong>Hello,</strong></p>
              <p>Leader, want to share this opportunity with you at the earliest possible condition,</p>
              <p>This information will be shared on this domain for 24/48 hour's. Over this journey we felt so many great things and going on,also getting progress day by day,</p>
              <p>So added new blockchain</p>
              <p>Polygon with native coin Matic,</p>
              <p>solutions with matic coin on polygon chain,with similar single leg distribution and deeper level plan with leadership pool arrangements,so leaders should remain the part of the system,</p>
              <p>The entire code is written over polygon blockchain,100% varified code, 100% decentralised and most important error free coding,no problem will appear once it is on floor,</p>
              <p>This business is going to start from tomorrow onwards.</p>
              <p>Ready with 55 matic coin in your wallet either trust, meta mask or token pocket.</p>
              <p>Packages are short and simple</p>
              <p><span className="theme_text">50/100/250/500/1000</span> matic coin</p>
              <p>And similar conditions..</p>
              <p>Copy this link for joining this maticbusiness</p>
              <p><a target="_blank" className="theme_text word-break" href="https://maticbusiness.com/0xcc56c58B880e1c8299D85d47279c34DD4E243cA2">https://maticbusiness.com/0xcc56c58B880e1c8299D85d47279c34DD4E243cA2</a></p>
              <p><a target="_blank" className="theme_text" href="https://maticbusiness.com/">www.maticbusiness.com</a></p>
              <hr />
              <p className="text-right mb-0"><strong>Thanks and regards</strong></p>
              </div>

            </div>
          </div>
        </div>

          <div className="container">
            <div className="banner_inner">
              <img className="banner_logo" src="img/big_logo.png" alt="" />
              <div className="text-center">
                <a target="_blank" class="popup-youtube" href="video/video.mp4">
                  <img class="video-icon" src="img/video-icon.png" alt="" />
                </a>
                 {/*<a target="_blank" class="popup-youtube" href="video/video2.mp4">
                  <img class="video-icon" src="img/video-icon.png" alt="" />
                </a>*/}
              </div>
              <p>100% BNB blockchain, verify, move</p>
            </div>
          </div>

          <div className="pb_30">
            <div className="container">
              {this.state.loading ? (
                
                <div className="loader_body">
                  <span className="loader">
                    <span className="small"></span>
                  </span>
                </div>
                
              ) : (null)}
              <div className="row cus_row">                
                <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner">
                    <img src="img/icon4.png" alt="" />
                    <h4>Contributors</h4>
                    <h5>{this.state.totalUsers}</h5>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner">
                    <img src="img/icon5.png" alt="" />
                    <h4>Contribution</h4>
                    <h5>{this.state.totalInvested} BNB</h5>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner">
                    <img src="img/icon6.png" alt="" />
                    <h4>Collections</h4>
                    <h5>{this.state.totalWithdrawn}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pb_30">
            <div className="container">
              <div className="sm_container">
                <div className="sm_container_bg">
                  <div className="sm_container_padding">
                    <div className="all_heading text-center">
                      <h3>Register to BNB blockchain</h3>
                    </div>
          
                    <div className="form-group mb_20">
                      <label className="white_label">Sponsor wallet address</label>
                      {(this.state.userInfo.checkpoint != undefined && this.state.userInfo.checkpoint > 0) ?(
                        <input className="cus_input" type="text" placeholder="Sponsor wallet address"
                          readOnly
                          value={this.state.user_referer}
                          onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                        />
                      ):(
                        <input className="cus_input" type="text" placeholder="Sponsor wallet addresss"
                          value={this.state.sponsor_address}
                          onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                        />
                      )}
                    </div>
                    <ul className="trx_btn">
                      {defaultAmountArr.map((amount,key) => {return(
                        <li key={key}>
                          <button className={this.state.amountBNB==amount?'active':'inactive'} 
                            onClick={e => this.setState({amountBNB:amount})}>
                            {amount+' BNB'}
                          </button>
                        </li>
                      )                              
                      })}
                    </ul>
                    <div className="text-center mt_20">
                      <button onClick={this.doJoinNow} className="grad_btn btn_300">Register</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pb_60">
            <div className="container">
              <div className="small_heading">
                <h5>ID - {this.state.YourAddress}</h5>
              </div>
              <div className="row cus_row">
                <div className="col-md-3 col-sm-3 col-6">
                  <div className="Personal_Details_inner">
                    <h4>Single leg family</h4>
                    <p>Up</p>
                    <h5>{this.state.GetUplineIncomeByUserId} BNB</h5>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 col-6">
                  <div className="Personal_Details_inner">
                    <h4>Single leg family</h4>
                    <p>Down</p>
                    <h5>{this.state.GetDownlineIncomeByUserId} BNB</h5>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 col-6">
                  <div className="Personal_Details_inner">
                    <h4>Level income or </h4>
                    <p>Sponsor income</p>
                    <h5>{this.state.userInfo.referrerBonus} BNB</h5>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 col-6">
                  <div className="Personal_Details_inner">
                    <h4>Your wallet </h4>
                    <p>balance</p>
                    <h5>{this.state.TotalBonus} BNB</h5>
                  </div>
                </div>
              </div>
              <div className="row cus_row">
                <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner">
                    <img src="img/icon1.png" alt="" />
                    <h4>Your contribution</h4>
                    <h5>{this.state.userInfo.amount} BNB</h5>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner">
                    <img src="img/icon2.png" alt="" />
                    <h4>Your collection</h4>
                    <h5>{this.state.userInfo.totalWithdrawn} BNB</h5>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner">
                    <img src="img/icon3.png" alt="" />
                    <h4>Your sponsor</h4>
                    <h5>{this.state.user_referer}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pb_30">
            <div className="container">
              <div className="sm_container">
                <div className="sm_container_bg">
                  <div className="sm_container_padding">
                    <div className="all_heading text-center">
                      <h3>Wallet balance</h3>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-lg-12 col-sm-12">
                        <div className="form-group mb_20">
                          <label className="white_label">Available BNB ({this.state.adminFee_percentage} % Admin Fee: {this.state.adminFee})</label>
                          <input className="cus_input" type="text" readOnly value={this.state.balance} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <div className="form-group mb_20">
                          <label className="white_label">Reinvestment Amount (BNB)</label>
                          <input className="cus_input" type="text" readOnly 
                          value={parseFloat(this.state.reinvest_percentage*this.state.balance/100).toFixed(5)} />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <div className="form-group mb_20">
                          <label className="white_label">Withdrawable Amount (BNB)</label>
                          <input className="cus_input" type="text" readOnly
                          value={parseFloat(this.state.withdrwal_percentage*this.state.balance/100).toFixed(5)} />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb_20">
                      <p className="white_label mb-0">Reinvestment percentage: <strong>{this.state.reinvest_percentage}%</strong></p>
                      <p className="white_label">Withdrawable percentage: <strong>{this.state.withdrwal_percentage}% </strong></p>
                    </div>
                    <div className="text-center mt_20">
                      <button onClick={this.doWithdrawal} className="grad_btn btn_300">Withdrawal</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pb_30">
            <div className="container">
                <div className="md_container">
                  <div className="all_heading text-center">
                    <h3>Single leg family</h3>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th>Level</th>
                          <th>User ID</th>
                          <th>Investment</th>
                          <th>Income</th>
                        </tr>
                      </thead>
                      <tbody>                       
                        {this.state.communityLevels.length ? (
                          this.state.communityLevels.length>0 ? (
                            this.state.communityLevels.map(function(val, index){
                              let class_name = 'lebel_'+val.level;
                              if(val.level == 0){
                                class_name = 'current_user';
                              }
                              return (
                                <tr key={`cl${index}`} className={class_name}>
                                  <td style={{width:'120px'}}>{val.levelText}</td>
                                  <td style={{width:'50%'}}>{val.username}</td>
                                  <td>{val.investment} BNB</td>
                                  <td>
                                  {val.income} BNB
                                  </td>
                                </tr>
                              )
                            })
                          ):(null)
                        ) : (
                        <tr>
                          <td colSpan="4" className="text-center">No Data Available!</td>
                        </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="md_container">
                  <div className="all_heading text-center">
                    <h3>Family list in levels</h3>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th> Level </th>
                          <th> Count </th>
                          <th> Invest Amount </th>
                          <th> Income </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.sponsorList.length ? (
                          this.state.sponsorList.length>0 ? (
                            this.state.sponsorList.map(function(val, index){
                              let class_name = 'lebel_'+val.level;
                              if(val.level == 0){
                                class_name = 'current_user';
                              }
                              return (
                                <tr key={`sl${index}`} className={class_name}>
                                  <td style={{width:'120px'}}>
                                    Level-{val.level}
                                  </td>
                                  <td>{val._noOfUser}</td>
                                  <td>{val._investment} BNB</td>
                                  <td>{val.percentage_amount} BNB {val.status?(`(${val.status})`):(null)}</td>
                                </tr>
                              )
                            })
                          ):(null)
                        ) : (
                        <tr>
                          <td colSpan="4" className="text-center">No Data Available!</td>
                        </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>

          <div className="pb_30">
            <div className="container">
              <div className="sm_container">
                <div className="sm_container_bg">
                  <div className="sm_container_padding">
                    <div className="all_heading text-center">
                      <h3>Your referral link</h3>
                    </div>
                    <h4 className="referal_text word-break">https://bnbbusiness.org/{this.state.YourAddress}</h4>
                  
                    <div className="text-center mt_20">
                      <CopyToClipboard text={`https://bnbbusiness.org/${this.state.YourAddressfull}`}
                        onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })
                        }>
                        <button className="grad_btn btn_300">Copy Link</button>
                      </CopyToClipboard>                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pb_30">
            <div className="container">
            <div className="md_container">
                <div className="mb_40">
                <div className="container">
                  <div className="all_heading text-center">
                    <h3>Plan PDF</h3>
                  </div>
                  <ul class="pdf_downlad_ul">
                    <li>
                      <a target="_blank" href="pdf/Binance Coin (BNB) Chinese_illustration.pdf">
                        <span><i class="fa fa-file-pdf-o"></i></span>
                        <h5>Chinese PDF</h5>
                        <p>Download  <i class="fa fa-download"></i></p>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="pdf/Binance Coin (BNB) English_illustration.pdf">
                        <span><i class="fa fa-file-pdf-o"></i></span>
                        <h5>English PDF</h5>
                        <p>Download  <i class="fa fa-download"></i></p>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="pdf/Binance Coin (BNB) French_illustration.pdf">
                        <span><i class="fa fa-file-pdf-o"></i></span>
                        <h5>French PDF</h5>
                        <p>Download  <i class="fa fa-download"></i></p>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="pdf/Binance Coin (BNB) German_illustration.pdf">
                        <span><i class="fa fa-file-pdf-o"></i></span>
                        <h5>German PDF</h5>
                        <p>Download  <i class="fa fa-download"></i></p>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="pdf/Binance Coin (BNB) Indonesian_illustration.pdf">
                        <span><i class="fa fa-file-pdf-o"></i></span>
                        <h5>Indonesian PDF</h5>
                        <p>Download  <i class="fa fa-download"></i></p>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="pdf/Binance Coin (BNB) Japanese_illustration.pdf">
                        <span><i class="fa fa-file-pdf-o"></i></span>
                        <h5>Japanese PDF</h5>
                        <p>Download  <i class="fa fa-download"></i></p>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="pdf/Binance Coin (BNB) Korean_illustration.pdf">
                        <span><i class="fa fa-file-pdf-o"></i></span>
                        <h5>Korean PDF</h5>
                        <p>Download  <i class="fa fa-download"></i></p>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="pdf/Binance Coin (BNB) Russian_illustration.pdf">
                        <span><i class="fa fa-file-pdf-o"></i></span>
                        <h5>Russian PDF</h5>
                        <p>Download  <i class="fa fa-download"></i></p>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="pdf/Binance Coin (BNB) Vietnamese_illustration.pdf">
                        <span><i class="fa fa-file-pdf-o"></i></span>
                        <h5>Vietnamese PDF</h5>
                        <p>Download  <i class="fa fa-download"></i></p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>        
            </div>
            </div>
          </div>

          <div className="pb_30">
          <div className="container">
              <div className="all_heading text-center">
                <h3>Plan <span>Video</span></h3>
              </div>
            <div className="row">
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div class="video_inner">
                  <a target="_blank" class="popup-youtube" href="video/video.mp4">
                    <img class="video-img" src="img/video_bg1.jpg" alt="" />
                    <img className="video-icon" src="img/video-icon.png" alt="" />
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div class="video_inner">
                  <a target="_blank" class="popup-youtube" href="video/video2.mp4">
                    <img class="video-img" src="img/video_bg2.jpg" alt="" />
                    <img className="video-icon" src="img/video-icon.png" alt="" />
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div class="video_inner">
                  <a target="_blank" class="popup-youtube" href="video/video3.mp4">
                    <img class="video-img" src="img/video_bg3.jpg" alt="" />
                    <img className="video-icon" src="img/video-icon.png" alt="" />
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>

        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    }
  }


  export default withRouter(withStyles(useStyles)(withSnackbar(Home)));
  //export default Home;