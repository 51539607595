import React, { Component } from 'react';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
       <div>
         <footer>
          <div className="container">
            <img className="footer_logo" src="img/logo_text.png" alt="" />
            <hr />
            <p>&copy; 2021 BNBbusiness All Rights Reserved. </p>
          </div>
        </footer>
  
          <a href="javascript:" id="return-to-top" className="scroll-down"><span> Go to Top</span></a>
          
          
          <div className="overlay">
            <div className="overlayDoor"></div>
            <div className="overlayContent">
              <div className="loader-logo">
                <div className="loader-circle"></div>
              </div>
            </div>
          </div>
       </div>
      );
    }
  }

  export default Footer;